import PropTypes from 'prop-types';

// Styles
import styles from './OneColumnBlock.module.css';

function OneColumnBlock({ children }) {
  return (
    <section className={styles.layout}>
      {children}
    </section>
  );
}

OneColumnBlock.propTypes = {
  children: PropTypes.node,
};

OneColumnBlock.defaultProps = {
  children: null,
};

export default OneColumnBlock;
